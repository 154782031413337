// @ts-ignore
import {Resource} from 'avoapp-react-common/dist/redux'
import {QueryParamConfigMap} from 'serialize-query-params'

export enum FilterType {
  SELECT = 'select',
  RESOURCE = 'resource',
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

interface BaseFilterConfig {
  filterType: FilterType
  label: string
  excludeFromForm?: boolean
}

export interface SelectFilterConfig extends BaseFilterConfig{
  filterType: FilterType.SELECT
  isMulti?: boolean
  options: {value: string | number | boolean, label: any}[]
}
export interface ResourceFilterConfig extends BaseFilterConfig{
  filterType: FilterType.RESOURCE
  resource: Resource
  isMulti?: boolean
  labelAccessor: string
  valueAccessor: string
}

interface OtherFilterConfig extends BaseFilterConfig {
  filterType: Exclude<FilterType, FilterType.SELECT | FilterType.RESOURCE>;
}

export type FilterConfig = OtherFilterConfig | SelectFilterConfig | ResourceFilterConfig

export interface UseQueryFilter {
  [key: string]: FilterConfig
}

export interface FilterConfigMapping {
    config: UseQueryFilter,
    mapping: QueryParamConfigMap,
    getUIFilters: (query: any) => any,
    getAPIFilters: (query: any) => any
}